/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, Slide } from 'react-toastify';
import { Switch, Route, withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { matchPath } from 'react-router';
import TagManager from 'react-gtm-module';

import {
  GTM_CODE,
  GTM_WIDGET_CODE,
  ANALYTIC_KEY,
  USER,
  CLIENT,
  ADMIN,
  AGENCY,
  TALENT,
  TALENT_PARTNER,
  AMBASSADOR,
  routes,
  REFERRAL,
} from 'containers/App/constants';
import { pageUpdate } from 'utils/Helper';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import PrivateLayout from 'components/Layouts/PrivateLayout';
import PublicLayout from 'components/Layouts/PublicLayout';
import AuthLayout from 'components/Layouts/AuthLayout';
import SignInLayout from 'components/Layouts/SignInLayout';
import SignInLayoutV2 from 'components/Layouts/SignInLayoutV2';
import ClientHomePage from 'containers/ClientHomePage/Loadable';
import StartProjectPage from 'containers/StartProjectPage/Loadable';
import RequestReceivedPage from 'containers/RequestReceivedPage/Loadable';
import AccountSettingsPage from 'containers/AccountSettingsPage/Loadable';
import MyProfilePage from 'containers/MyProfilePage/Loadable';
import LoginPage from 'containers/Auth/LoginPage/Loadable';
import SignUpPage from 'containers/Auth/SignUp/Loadable';
import SelectCompany from 'containers/Auth/ClientSignUp/Loadable';
import ClientVerificationPage from 'containers/Auth/ClientSignUp/LoadableClientValidation';
import CreatePassword from 'containers/Auth/ClientSignUp/LoadableCreatePassword';
import ForgotPasswordPage from 'containers/Auth/ForgotPasswordPage/Loadable';
import VerifyEmailPage from 'containers/Auth/VerifyEmailPage/Loadable';
import ResetPasswordPage from 'containers/Auth/ResetPasswordPage/Loadable';
import PasswordResetSuccess from 'containers/Auth/PasswordResetSuccess/Loadable';

import VerificationPage from 'containers/Auth/Verification/Loadable';
import TalentEmailVerification from 'containers/Auth/TalentEmailVerification/Loadable';
import AboutYouPage from 'containers/Auth/Client/AboutYouPage/Loadable';
import AboutCompanyPage from 'containers/Auth/Client/AboutCompanyPage/Loadable';
import CompanyLocationPage from 'containers/Auth/Client/CompanyLocationPage/Loadable';
import AgencyAboutYouPage from 'containers/Auth/Talent/AboutYouPage/Loadable';
import AgencyCompanyPage from 'containers/Auth/Talent/CompanyPage/Loadable';
import AddTalentsPage from 'containers/Auth/Talent/AddTalentsPage/Loadable';
import AgencyCertificatesPage from 'containers/Auth/Talent/AgencyCertificatesPage/Loadable';
import AgencyCredentialsPage from 'containers/Auth/Talent/AgencyCredentialsPage/Loadable';
import PayoutDetailsPage from 'containers/Auth/Talent/PayoutDetailsPage/Loadable';
import AgencyAccountSettingsPage from 'containers/AgencyAccountSettingsPage/Loadable';
import DocumentsPage from 'containers/Auth/Talent/DocumentsPage/Loadable';
import AddDirectorsPage from 'containers/Auth/Talent/AddDirectorsPage/Loadable';
import PersonalDetails from 'containers/Auth/PersonalDetails/Loadable';
import KeyProjects from 'containers/Auth/KeyProjects/Loadable';
import TalentSkills from 'containers/Auth/TalentSkills/Loadable';
import Preferences from 'containers/Auth/Preferences/Loadable';
import WorkExperience from 'containers/Auth/WorkExperience/Loadable';
import SalaryAndBilling from 'containers/Auth/SalaryAndBilling/Loadable';
import DocumentUpload from 'containers/Auth/DocumentUpload/Loadable';
import TalentListingPage from 'containers/TalentListingPage/Loadable';
import EducationCertification from 'containers/Auth/Education-Certification/Loadable';
import UserReferral from 'containers/Auth/UserReferral/Loadable';
import ClientProfile from 'containers/ClientProfile/Loadable';
import ClientProfilePage from 'containers/Client/ProfilePage/Loadable';
import ClientAccountSettingsPage from 'containers/ClientAccountSettingsPage/Loadable';
import AdminAccountSettingsPage from 'containers/AdminAccountSettingsPage/Loadable';
import AdminDashboard from 'containers/Admin/Dashboard/Loadable';
import AdminProjects from 'containers/Admin/Projects/Loadable';
import AdminClients from 'containers/Admin/Clients/Loadable';
import AdminAgencies from 'containers/Admin/Agencies/Loadable';
import AdminTalents from 'containers/Admin/Talents/Loadable';
import ScheduleInterview from 'components/Modals/ScheduleInterview/Loadable';
import AdminTeams from 'containers/Admin/Teams/Loadable';
import ClientDetailPage from 'containers/Admin/ClientDetailPage/Loadable';
import AgencyDetailPage from 'containers/Admin/AgencyDetailPage/Loadable';
import InterviewRequests from 'containers/Admin/InterviewRequests/Loadable';
import ClientInterviewRequests from 'containers/Client/InterviewRequests/Loadable';
import ClientInterviewDetailPage from 'containers/Client/InterviewDetailPage/Loadable';
import InterviewDetailPage from 'containers/Admin/InterviewDetailPage/Loadable';
import Referrals from 'containers/Admin/Referrals/Loadable';
import TalentPartners from 'containers/Admin/TalentPartners/Loadable';
import TalentPartnersDetailPage from 'containers/Admin/TalentPartnersDetailPage/Loadable';
import AmbassadorDetailPage from 'containers/Admin/AmbassadorDetailPage/Loadable';
import Ambassadors from 'containers/Admin/Ambassadors/Loadable';
import AgencyProfilePage from 'containers/Agency/ProfilePage/Loadable';
import ClientBrief from 'containers/Client/Briefs/Loadable';
import ClientProjects from 'containers/Client/Projects/Loadable';
import ClientProjectDetailPage from 'containers/Client/ProjectDetailPage/Loadable';
import ClientTalents from 'containers/Client/Talents/Loadable';
import ClientBilling from 'containers/Client/Billing/Loadable';
import ClientContracts from 'containers/Client/Contracts/Loadable';
import ClientPayments from 'containers/Client/Payments/Loadable';
import Timesheets from 'containers/Timesheets/Loadable';
import TalentBriefs from 'containers/Talent/Briefs/Loadable';
import TalentKnowledgeBase from 'containers/Talent/KnowledgeBase/Loadable';
import TalentCareerPath from 'containers/Talent/CareerPath/Loadable';
import TalentLearningDevelopment from 'containers/Talent/LearningDevelopment/Loadable';
import TalentPerks from 'containers/Talent/Perks/Loadable';
import ClientPerks from 'containers/Client/Perks/Loadable';
import TalentWellbeing from 'containers/Talent/Wellbeing/Loadable';
import Dashboard from 'containers/Talent/Dashboard/Loadable';
import Invoices from 'containers/CommingSoonPages/Invoices/Loadable';
import AgencyStatements from 'containers/Agency/Statements/Loadable';
import AgencyPlanning from 'containers/Agency/Planning/Loadable';
import AgencyMyTeam from 'containers/Agency/MyTeam/Loadable';
import AgencyQuotes from 'containers/Agency/Quotes/Loadable';
import AgencyQuoteDetail from 'containers/Agency/QuoteDetail/Loadable';
import MyProjects from 'containers/Talent/MyProjects/Loadable';
import MyProjectDetail from 'containers/Talent/MyProjectDetail/Loadable';
import AgencyProjectDetail from 'containers/Agency/ProjectDetail/Loadable';
import MyTeams from 'containers/Talent/MyTeams/Loadable';
import TeamsDetails from 'containers/Talent/TeamsDetails/Loadable';
import Quotes from 'containers/Quotes/Loadable';
import QuoteDetail from 'containers/QuoteDetail/Loadable';
import TalentProfileRedirect from 'containers/TalentProfileRedirect/Loadable';
import FeatureJobWidget from 'containers/ExternalComponents/FeatureJobWidget';
import FeatureDevlopersWidget from 'containers/ExternalComponents/FeatureDevlopersWidget';
import SignupWidget from 'containers/ExternalComponents/SignupWidget';
import ClientSignupWidget from 'containers/ExternalComponents/ClientSignupWidget';
import RecruiterDashboard from 'containers/Recruiter/Dashboard/Loadable';
import RecruiterTalent from 'containers/Recruiter/Talent/Loadable';
import RecruiterBriefs from 'containers/Recruiter/Briefs/Loadable';
import RecruiterAboutYouPage from 'containers/Auth/Recruiter/AboutYouPage/Loadable';
import RecruiterAboutCompanyPage from 'containers/Auth/Recruiter/AboutCompanyPage/Loadable';
import AmbassadorAboutYouPage from 'containers/Auth/Ambassador/AboutYouPage/Loadable';
import AmbassadorDashboard from 'containers/Ambassador/Dashboard/Loadable';
import AmbassadorTalents from 'containers/Ambassador/Talent';
import AmbassadorClients from 'containers/Ambassador/Client';
import ValidateSkills from 'containers/Talent/ValidateSkills/Loadable';
import ProfilePage from 'containers/ProfilePage/Loadable';
import JobBriefPage from 'containers/PublicPages/JobBriefPage/Loadable';
import ReferralLink from 'containers/PublicPages/ReferralLink/Loadable';
import AgencyBriefs from 'containers/Agency/Briefs/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import AdminCompanies from 'containers/Admin/Companies';

// team components
import CreateTeamPage from 'containers/Auth/Team/CreateTeam/Loadable';
import AddTeamPage from 'containers/Auth/Team/AddTeam/Loadable';
import ViewTeamPage from 'containers/Auth/Team/ViewTeam/Loadable';

// Client create-project components
import CreateProjectPage from 'containers/Client/CreateProject/Loadable';
import CheckoutPage from 'containers/Client/CheckoutPage/Loadable';

// Client create-brief components
import CreateBriefPage from 'containers/Client/AddBriefPage/Loadable';

// team layout
import TeamLayout from 'components/Layouts/TeamLayout';
import InterviewFeedbackPage from 'containers/Client/InterviewFeedbackPage/Loadable';
import HireTeamPage from 'containers/Client/HireTeam/Loadable';
import TalentWallet from 'containers/Talent/Wallet';
import AddACompanyPage from 'containers/Admin/AddACompanyPage';
import WalletTransactions from 'containers/Talent/Wallet/WalletTransactions';
import InviteClient from 'containers/Admin/InviteClient';
import AddEditTalent from 'containers/Agency/MyTeam/components/AddEditTalentLoadable';
import UnSubscribePage from 'containers/PublicPages/UnSubscribePage';
import VerifyLinkedIn from 'containers/Auth/VerifyLinkedIn';

import Hire from 'containers/Client/Hire/Loadable';
import HireTalentTeam from 'containers/Client/Hire/LoadableHireTeamTalent';
import AmbassadorDocumentsUpload from 'containers/Auth/Ambassador/DocumentUpload/Loadable';
import RecruiterDocumentsUpload from 'containers/Auth/Recruiter/DocumentUpload/Loadable';
import CompetitionList from 'containers/Client/Competitions/CompetitionList/Loadable';
import CreateCompetition from 'containers/Client/Competitions/CreateCompetition/Loadable';
import CompetitionDetail from 'containers/Client/Competitions/CompetitionDetailPage/Loadable';
import TalentCompetitionList from 'containers/Talent/TalentCompetitions/TalentCompetitionList/Loadable';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import request from 'utils/request';
import { setClientDetails } from './reducer';
import { initAmplitude } from './utils';
import SignUp from 'containers/Auth/SignUp';
import StorageService from 'utils/StorageService';

let privateRoutes = [
  { component: Dashboard, key: 'Talent_Dashboard' },
  { component: MyProfilePage, key: 'Talent_MyProfilePage' },
  { component: AccountSettingsPage, key: 'AccountSettingsPage' },
  { component: Timesheets, key: 'Talent_Timesheets' },
  { component: TalentBriefs, key: 'TalentBriefs' },
  { component: TalentKnowledgeBase, key: 'TalentKnowledgeBase' },
  { component: TalentCareerPath, key: 'TalentCareerPath' },
  { component: TalentLearningDevelopment, key: 'TalentLearningDevelopment' },
  { component: TalentPerks, key: 'TalentPerks' },
  { component: ClientPerks, key: 'ClientPerks' },
  { component: CreateBriefPage, key: 'ClientCreateBriefPage' },
  { component: TalentWellbeing, key: 'TalentWellbeing' },
  { component: Invoices, key: 'Talent_Invoices' },
  { component: MyProjects, key: 'Talent_MyProjects' },
  { component: MyTeams, key: 'Talent_MyTeams' },
  { component: MyProjectDetail, key: 'MyProjectDetail' },
  { component: Dashboard, key: 'Agency_Dashboard' },
  { component: AgencyProfilePage, key: 'AgencyProfilePage' },
  { component: AgencyAccountSettingsPage, key: 'AgencyAccountSettingsPage' },
  { component: AgencyQuotes, key: 'AgencyQuotes' },
  { component: AgencyQuoteDetail, key: 'AgencyQuoteDetail' },
  { component: MyProjects, key: 'Agency_MyProjects' },
  { component: AgencyProjectDetail, key: 'AgencyProjectDetail' },
  { component: Timesheets, key: 'Agency_Timesheets' },
  { component: Invoices, key: 'Agency_Invoices' },
  { component: AgencyStatements, key: 'AgencyStatements' },
  { component: AgencyMyTeam, key: 'AgencyMyTeam' },
  { component: AgencyPlanning, key: 'AgencyPlanning' },
  { component: MyProfilePage, key: 'Agency_MyProfilePage' },
  { component: ClientHomePage, key: 'Client_Dashboard' },
  { component: StartProjectPage, key: 'StartProjectPage' },
  { component: RequestReceivedPage, key: 'RequestReceivedPage' },
  { component: TalentListingPage, key: 'TalentListingPage' },
  { component: ClientProfile, key: 'ClientProfile' },
  { component: MyProfilePage, key: 'Client_MyProfilePage' },
  { component: ClientAccountSettingsPage, key: 'ClientAccountSettingsPage' },
  { component: ClientBrief, key: 'ClientBrief' },
  { component: ClientProjects, key: 'ClientProjects' },
  { component: ClientTalents, key: 'ClientTalents' },
  { component: ClientBilling, key: 'ClientBilling' },
  { component: ClientContracts, key: 'ClientContracts' },
  { component: ClientPayments, key: 'ClientPayments' },
  { component: ClientProjectDetailPage, key: 'ClientProjectDetailPage' },
  { component: Timesheets, key: 'Client_Timesheets' },
  { component: AdminDashboard, key: 'Admin_Dashboard' },
  { component: AdminProjects, key: 'AdminProjects' },
  { component: ClientBrief, key: 'Admin_Brief' },
  { component: InterviewRequests, key: 'InterviewRequests' },
  { component: Quotes, key: 'Quotes' },
  { component: AdminTalents, key: 'AdminTalents' },
  { component: AdminTeams, key: 'AdminTeams' },
  { component: Timesheets, key: 'Admin_Timesheets' },
  { component: AdminClients, key: 'AdminClients' },
  { component: AdminAgencies, key: 'AdminAgencies' },
  { component: ClientProjectDetailPage, key: 'Admin_ProjectDetailPage' },
  { component: CreateProjectPage, key: 'ClientCreateProject' },
  { component: CreateProjectPage, key: 'ClientEditProject' },
  { component: CreateCompetition, key: 'ClientCreateCompetition' },
  { component: CreateCompetition, key: 'ClientEditCompetition' },
  { component: CreateProjectPage, key: 'AdminEditProject' },
  { component: CreateProjectPage, key: 'AdminCreateProject' },
  { component: ClientDetailPage, key: 'ClientDetailPage' },
  { component: AgencyDetailPage, key: 'AgencyDetailPage' },
  { component: InterviewDetailPage, key: 'InterviewDetailPage' },
  { component: MyProfilePage, key: 'Admin_MyProfilePage' },
  { component: AdminAccountSettingsPage, key: 'AdminAccountSettingsPage' },
  { component: QuoteDetail, key: 'QuoteDetail' },
  { component: Referrals, key: 'Referrals' },
  { component: TalentPartners, key: 'TalentPartners' },
  { component: TalentPartnersDetailPage, key: 'TalentPartnersDetailPage' },
  { component: RecruiterDashboard, key: 'RecruiterDashboard' },
  { component: Ambassadors, key: 'Ambassadors' },
  { component: AmbassadorDetailPage, key: 'AmbassadorDetailPage' },
  { component: RecruiterBriefs, key: 'RecruiterBriefs' },
  { component: AmbassadorDashboard, key: 'AmbassadorDashboard' },
  { component: AmbassadorTalents, key: 'AmbassadorTalents' },
  { component: AmbassadorClients, key: 'AmbassadorClients' },
  { component: RecruiterTalent, key: 'RecruiterTalent' },
  { component: ValidateSkills, key: 'ValidateSkills' },
  { component: AmbassadorDashboard, key: 'TalentReferEarn' },
  { component: AmbassadorTalents, key: 'TalentReferralTalent' },
  { component: AmbassadorClients, key: 'TalentReferralClients' },
  { component: AgencyBriefs, key: 'AgencyBriefs' },
  { component: ClientInterviewRequests, key: 'ClientInterviews' },
  { component: ClientInterviewDetailPage, key: 'ClientInterviewDetailPage' },
  { component: InterviewFeedbackPage, key: 'InterviewFeedback' },
  { component: HireTeamPage, key: 'ClientHireTeam' },
  { component: CompetitionList, key: 'ClientCompetitions' },
  { component: ScheduleInterview, key: 'ClientScheduleInterview' },
  { component: TalentWallet, key: 'TalentWallet' },
  { component: AddACompanyPage, key: 'AdminAddACompanyPage' },
  { component: WalletTransactions, key: 'TalentWalletTransactions' },
  { component: AdminCompanies, key: 'AdminCompanyListing' },
  { component: InviteClient, key: 'AdminInviteClientPage' },
  { component: AddEditTalent, key: 'AgencyAddEditTalent' },
  { component: TalentCompetitionList, key: 'TalentCompetitions' },
];

let publicRoutes = [
  { component: CompetitionDetail, key: 'ClientCompetitionDetailPage' },
  { component: ProfilePage, key: 'ProfilePage', title: 'Talent profile' },
  { component: JobBriefPage, key: 'JobBriefPage', title: 'Jobs' },
  { component: ReferralLink, key: 'ReferralLink', title: 'Referral Link' },
  { component: TeamsDetails, key: 'Talent_TeamsDetails', title: 'Team Details' },
  { component: ClientProfilePage, key: 'ClientProfilePage', title: 'Profile' },
];

privateRoutes = privateRoutes.map((i) => {
  const getDetails = routes.find((r) => r.key === i.key);
  if (!getDetails) {
    return { ...i, paths: [] };
  }
  return { ...i, ...getDetails };
});

publicRoutes = publicRoutes.map((i) => {
  const getDetails = routes.find((r) => r.key === i.key);
  if (!getDetails) {
    return { ...i, paths: [] };
  }
  return { ...i, ...getDetails };
});

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUpdate() {
    pageUpdate();
  }

  componentDidMount() {
    const { location, history, dispatch } = this.props;
    const widgetPath = ['/feature-job-widget', '/feature-developers-widget', '/signup-widget', '/client-widget', '/client-signup-widget'];
    const match = widgetPath.some((path) =>
      matchPath(location.pathname, {
        path,
        exact: true,
        strict: false,
      }),
    );
    StorageService.delete('filterObject');
    ReactGA.initialize(ANALYTIC_KEY);
    initAmplitude();
    if (match) {
      TagManager.initialize({ gtmId: GTM_WIDGET_CODE });
    } else {
      TagManager.initialize({ gtmId: GTM_CODE });
    }
    window.addEventListener('DOMContentLoaded', function () {
      // Check if the app is inside an iframe
      if (window.self !== window.top) {
        // Hide the Atlas chat div
        const atlasChatDiv = document.getElementById('atlas-chat');
        if (atlasChatDiv) {
          atlasChatDiv.style.display = 'none';
        }
      }
    });

    // const unlisten = history.listen(() => {

    //   const data = {
    //     method: 'GET',
    //   };
    //   const requestURL = `${API_URL}${USER}/client-header-details`;
    // request(requestURL, data).then((response) => {
    //   this.props.dispatch(setClientDetails(response?.data));
    // });
    // });
  }

  render() {
    const login = '/login';
    const signup = '/signup';
    const forgotPwd = '/forgot-password';
    const verifyEmail = '/verifyemail';
    const pwdResetSuccess = '/password-reset-success';
    const verification = '/verification';
    const resetPwd = '/reset-password';
    const verificationMail = '/verification-mail';
    const selectCompany = '/select-company';
    const { history } = this.props;

    return (
      <div>
        <ToastContainer
          className="Toast"
          type="default"
          position="top-center"
          autoClose={3000}
          hideProgressBar
          pauseOnHover={false}
          transition={Slide}
          draggable={false}
        />

        <Switch>
          <Route path="/unsubscribe" component={UnSubscribePage} />
          <Route path="/feature-job-widget" component={FeatureJobWidget} />
          <Route path="/feature-developers-widget" component={FeatureDevlopersWidget} />
          <Route path="/client-signup-widget" component={SignUp} />
          <Route path="/signup-widget" component={SignupWidget} />
          <Route path="/client-widget" component={ClientSignupWidget} />

          {/* Client/Company Sign-up Page Routes */}
          <Route path="/hire" exact component={Hire} />
          <Route path={`/hire/:usertype`} exact component={HireTalentTeam} />
          <Route exact path={selectCompany} component={SelectCompany} />
          <Route exact path={`${CLIENT}${verification}`} component={ClientVerificationPage} />
          <Route exact path={`${CLIENT}/create-password`} component={CreatePassword} />

          <SignInLayoutV2 exact path="/" component={SignUpPage} />
          <SignInLayoutV2 exact path="/linkedin" component={VerifyLinkedIn} />
          <SignInLayoutV2 path={signup} component={SignUpPage} />
          <SignInLayoutV2 path={login} component={LoginPage} />
          <SignInLayoutV2 path={forgotPwd} component={ForgotPasswordPage} />
          <SignInLayoutV2 path={verifyEmail} component={VerifyEmailPage} />

          <AuthLayout path={`${USER}/profile-view/:talentID`} component={TalentProfileRedirect} redirectToPage />

          <SignInLayoutV2 path={`${TALENT}${login}`} component={LoginPage} />
          <SignInLayoutV2 path={`${TALENT}${signup}/:talentToken`} component={SignUpPage} />
          <SignInLayoutV2 path={`${TALENT}${signup}`} component={SignUpPage} />
          <SignInLayoutV2 path={`${TALENT}${verifyEmail}`} component={VerifyEmailPage} />
          <SignInLayoutV2 path={`${TALENT}${forgotPwd}`} component={ForgotPasswordPage} />
          <SignInLayoutV2 path={`${TALENT}${pwdResetSuccess}`} component={PasswordResetSuccess} />
          <SignInLayoutV2 path={`${TALENT}${verification}`} component={VerificationPage} />
          <SignInLayoutV2 path={`${TALENT}${verificationMail}/:emailID`} component={TalentEmailVerification} />
          <SignInLayoutV2 path={`${TALENT}${resetPwd}/:resetToken`} component={ResetPasswordPage} />
          <SignInLayoutV2 path={`${TALENT}${REFERRAL}/:userId`} component={UserReferral} />
          <AuthLayout path={`${TALENT}/about-you`} component={PersonalDetails} />
          <AuthLayout path={`${TALENT}/qualifications`} component={EducationCertification} />
          <AuthLayout path={`${TALENT}/experience`} component={WorkExperience} />
          <AuthLayout path={`${TALENT}/projects`} component={KeyProjects} />
          <AuthLayout path={`${TALENT}/skills`} component={TalentSkills} />
          <AuthLayout path={`${TALENT}/preferences`} component={Preferences} />
          <AuthLayout path={`${TALENT}/salary-billing`} component={SalaryAndBilling} />
          <AuthLayout path={`${TALENT}/document-upload`} component={DocumentUpload} />

          <TeamLayout path={`${TALENT}/create-team`} component={CreateTeamPage} />
          <TeamLayout path={`${TALENT}/add-team`} component={AddTeamPage} />
          <TeamLayout path={`${TALENT}/teams`} component={ViewTeamPage} />

          <SignInLayout exact path={AGENCY} component={SignUpPage} />
          <SignInLayout path={`${AGENCY}${login}`} component={LoginPage} />
          <SignInLayout path={`${AGENCY}${signup}/:talentToken`} component={SignUpPage} />
          <SignInLayout path={`${AGENCY}${signup}`} component={SignUpPage} />
          <SignInLayout path={`${AGENCY}${verifyEmail}`} component={VerifyEmailPage} />
          <SignInLayout path={`${AGENCY}${forgotPwd}`} component={ForgotPasswordPage} />
          <SignInLayout path={`${AGENCY}${pwdResetSuccess}`} component={PasswordResetSuccess} />
          <SignInLayout path={`${AGENCY}${verification}`} component={VerificationPage} />
          <SignInLayout path={`${AGENCY}${verificationMail}/:emailID`} component={TalentEmailVerification} />
          <SignInLayout path={`${AGENCY}${resetPwd}/:resetToken`} component={ResetPasswordPage} />
          <SignInLayout path={`${AGENCY}${REFERRAL}/:userId`} component={UserReferral} />
          <AuthLayout path={`${AGENCY}/about-you`} component={AgencyAboutYouPage} />
          <AuthLayout path={`${AGENCY}/about-company`} component={AgencyCompanyPage} />
          <AuthLayout path={`${AGENCY}/add-talents`} component={AddTalentsPage} />
          <AuthLayout path={`${AGENCY}/certifications`} component={AgencyCertificatesPage} />
          <AuthLayout path={`${AGENCY}/credentials`} component={AgencyCredentialsPage} />
          <AuthLayout path={`${AGENCY}/payout`} component={PayoutDetailsPage} />
          <AuthLayout path={`${AGENCY}/add-directors-shareholders`} component={AddDirectorsPage} />
          <AuthLayout path={`${AGENCY}/documents`} component={DocumentsPage} />

          <SignInLayoutV2 path={`${CLIENT}${login}`} component={LoginPage} />
          <SignInLayoutV2 path={`${CLIENT}${signup}`} component={SignUpPage} />
          <SignInLayoutV2 path={`${CLIENT}${forgotPwd}`} component={ForgotPasswordPage} />
          <SignInLayoutV2 path={`${CLIENT}${verifyEmail}`} component={VerifyEmailPage} />
          <SignInLayoutV2 path={`${CLIENT}${resetPwd}/:resetToken`} component={ResetPasswordPage} />
          <SignInLayoutV2 path={`${CLIENT}${pwdResetSuccess}`} component={PasswordResetSuccess} />
          <SignInLayoutV2 path={`${CLIENT}${REFERRAL}/:userId`} component={UserReferral} />
          <AuthLayout path={`${CLIENT}/about-you`} component={AboutYouPage} />
          <AuthLayout path={`${CLIENT}/about-company`} component={AboutCompanyPage} />
          <AuthLayout path={`${CLIENT}/company-location`} component={CompanyLocationPage} />
          <TeamLayout path={`${CLIENT}/checkout`} component={CheckoutPage} />

          <SignInLayoutV2 path={`${TALENT_PARTNER}${login}`} component={LoginPage} />
          <SignInLayoutV2 path={`${TALENT_PARTNER}${signup}`} component={SignUpPage} />
          <SignInLayoutV2 path={`${TALENT_PARTNER}${verification}`} component={VerificationPage} />
          <AuthLayout path={`${TALENT_PARTNER}/about-you`} component={RecruiterAboutYouPage} />
          <AuthLayout path={`${TALENT_PARTNER}/about-company`} component={RecruiterAboutCompanyPage} />
          <AuthLayout path={`${TALENT_PARTNER}/document-upload`} component={RecruiterDocumentsUpload} />

          <SignInLayoutV2 path={`${AMBASSADOR}${login}`} component={LoginPage} />
          <SignInLayoutV2 path={`${AMBASSADOR}${signup}`} component={SignUpPage} />
          <SignInLayoutV2 path={`${AMBASSADOR}${verification}`} component={VerificationPage} />
          <AuthLayout path={`${AMBASSADOR}/about-you`} component={AmbassadorAboutYouPage} />
          <AuthLayout path={`${AMBASSADOR}/document-upload`} component={AmbassadorDocumentsUpload} />

          <SignInLayoutV2 path={`${ADMIN}${login}`} component={LoginPage} />

          {privateRoutes.map((route) => (
            <PrivateLayout key={route.key} path={route.paths.map((p) => p.path)} exact component={route.component} history={history} />
          ))}

          {publicRoutes.map((route) => (
            <PublicLayout key={route.key} path={route.paths.map((p) => p.path)} exact component={route.component} {...route} />
          ))}

          <SignInLayout path="" component={NotFoundPage} />
        </Switch>
      </div>
    );
  }
}

App.defaultProps = {
  location: {},
  history: {},
};

App.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

const withConnect = connect(null, null);

export default compose(withConnect)(withRouter(App));
